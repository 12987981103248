import React from 'react';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import ConfigureAccount from './pages/auth/ConfigureAccount';
import Dashboard from './pages/dashboard/Dashboard';
import Experiences from './pages/dashboard/Experiences';
import Models from './pages/dashboard/Models';
import ProtectedRoute from './components/ProtectedRoute';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route path="configure-account" element={<ConfigureAccount />} />
      <Route path="/experiences" element={<Experiences />} />
      <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/models" element={<ProtectedRoute><Models /></ProtectedRoute>} />
    </Route>
  ),
  {
    future: {
      v7_startTransition: true,           // Active React.startTransition pour gérer les transitions
      v7_relativeSplatPath: true,         // Corrige les chemins relatifs dans les routes splat
      v7_fetcherPersist: true,            // Persiste les fetchers même après navigation
      v7_normalizeFormMethod: true,       // Normalise les méthodes des formulaires en majuscules
      v7_partialHydration: true,          // Change le comportement d'hydratation partielle
      v7_skipActionErrorRevalidation: true, // Ignore les revalidations après des erreurs d'actions 4xx/5xx
    },
  }
);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
