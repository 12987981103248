import React, { useState, useEffect, useCallback } from 'react';
import { doc, getDoc, updateDoc, runTransaction, collection, query, orderBy, getDocs, addDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { auth, db, storage } from '../../services/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { 
  PlusIcon, 
  DocumentArrowUpIcon, 
  DocumentTextIcon, 
  AcademicCapIcon, 
  PencilIcon, 
  BriefcaseIcon, 
  ArrowLongRightIcon,
  SparklesIcon,
  TrashIcon, 
  ChevronDownIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { setPersistence, browserLocalPersistence } from 'firebase/auth';
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';
import NavBar from '../../components/NavBar';
import { Link } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Authentification configurée avec persistance locale
  })
  .catch((error) => {
    console.error("Erreur lors de la définition de la persistance :", error);
  });

// Fonction pour générer une couleur déterministe basée sur une chaîne
const getColorFromString = (str) => {
  if (!str) return 'bg-gray-500'; // Couleur par défaut si la chaîne est undefined ou vide
  
  const colors = [
    'bg-red-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500', 
    'bg-pink-500', 'bg-indigo-500', 'bg-teal-500'
  ];
  
  let hash = 0;
  for (let i = 0; i <str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
};

const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: 'long'
  });
};

const Experiences = () => {
  const [userLoaded, setUserLoaded] = useState(false);
  const [checkingSession, setCheckingSession] = useState(true);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    profilePicture: '',
    credits: 0
  });
  const [experiences, setExperiences] = useState([]);
  const [education, setEducation] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [experienceToDelete, setExperienceToDelete] = useState(null);
  const [modalType, setModalType] = useState('experience');
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [experience, setExperience] = useState({});
  const [loadingEducation, setLoadingEducation] = useState(true);
  const [isUploadExpanded, setIsUploadExpanded] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [showEducationModal, setShowEducationModal] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [educationToDelete, setEducationToDelete] = useState(null);
  const [showDeleteEducationModal, setShowDeleteEducationModal] = useState(false);
  const [newEducation, setNewEducation] = useState({
    graduationYear: '',
    schoolName: '',
    diploma: '',
    city: ''
  });
  const [interests, setInterests] = useState([]);
  const [skills, setSkills] = useState([]);
  const [showInterestModal, setShowInterestModal] = useState(false);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [newInterest, setNewInterest] = useState('');
  const [newSkill, setNewSkill] = useState('');
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [processingCV, setProcessingCV] = useState(false);
  const [processingSteps, setProcessingSteps] = useState({
    upload: { status: 'pending', label: 'Téléchargement du CV' },
    extraction: { status: 'pending', label: 'Extraction du texte' },
    analysis: { status: 'pending', label: 'Analyse du contenu' },
    saving: { status: 'pending', label: 'Sauvegarde des données' }
  });

  // Fonction pour mettre à jour une étape du traitement
  const updateProcessingStep = (step, status) => {
    setProcessingSteps(prev => ({
      ...prev,
      [step]: { ...prev[step], status }
    }));
  };

  // Fonction pour récupérer l'ID utilisateur authentifié
  const getUserId = () => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.warn("Utilisateur non authentifié");
      return null;
    }
    return userId;
  };

  // Fonction pour charger les données utilisateur
  const fetchUserData = useCallback(async () => {
    try {
      const userId = getUserId();
      if (!userId) return;

      const userDoc = await getDoc(doc(db, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
      }
    } catch (error) {
      console.error('Erreur lors du chargement des données utilisateur :', error);
    }
  }, []);

  // Fonction pour récupérer les expériences
  const fetchExperiences = useCallback(async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        console.warn("Utilisateur non authentifié");
        return;
      }

      const experiencesRef = collection(db, `users/${userId}/experiences`);
      const q = query(experiencesRef, orderBy("startDate", "desc"));
      const querySnapshot = await getDocs(q);
      
      const experiencesData = [];
      querySnapshot.forEach((doc) => {
        experiencesData.push({ id: doc.id, ...doc.data() });
      });
      
      setExperiences(experiencesData);
    } catch (error) {
      console.error("Erreur lors de la récupération des expériences:", error);
    }
  }, []);

  // Fonction pour charger les formations
  const fetchEducation = useCallback(async () => {
    setLoadingEducation(true);
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        console.warn("Utilisateur non authentifié");
        return;
      }

      const educationRef = collection(db, `users/${userId}/education`);
      const q = query(educationRef, orderBy("timestamp", "desc"));
      const querySnapshot = await getDocs(q);
      
      const educationData = [];
      querySnapshot.forEach((doc) => {
        educationData.push({ id: doc.id, ...doc.data() });
      });
      
      setEducation(educationData);
    } catch (error) {
      console.error("Erreur lors de la récupération des formations:", error);
    } finally {
      setLoadingEducation(false);
    }
  }, []);

  // Fonction pour charger les centres d'intérêts
  const fetchInterests = useCallback(async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const interestsRef = collection(db, `users/${userId}/interests`);
      const interestsSnapshot = await getDocs(interestsRef);
      const interestsList = interestsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setInterests(interestsList);
    } catch (error) {
      console.error("Erreur lors de la récupération des centres d'intérêt:", error);
    }
  }, []);

  // Fonction pour charger les compétences
  const fetchSkills = useCallback(async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const skillsRef = collection(db, `users/${userId}/skills`);
      const skillsSnapshot = await getDocs(skillsRef);
      const skillsList = skillsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSkills(skillsList);
    } catch (error) {
      console.error("Erreur lors de la récupération des compétences:", error);
    }
  }, []);

  // Effet pour l'authentification
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData();
        fetchExperiences();
        fetchEducation();
        fetchInterests();
        fetchSkills();
      } else {
        navigate('/login');
      }
      setCheckingSession(false);
    });

    return () => unsubscribe();
  }, [navigate, fetchUserData, fetchExperiences, fetchEducation, fetchInterests, fetchSkills]);

  // Fonction pour télécharger le CV
  const handleUploadCV = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      const userId = getUserId();
      if (!userId) return;

      const fileRef = ref(storage, `cv_uploads/${file.name}`);
      await uploadBytes(fileRef, file, {
        customMetadata: { userId: userId },
      });

      const fileUrl = await getDownloadURL(fileRef);
      console.log('CV uploaded:', fileUrl);

      alert("CV déposé avec succès ! Le traitement commencera sous peu.");
    } catch (error) {
      console.error("Erreur lors du upload du CV :", error);
      alert("Erreur lors du dépôt du CV. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour gérer les suppressions
  const handleDelete = async (type, index) => {
    setLoading(true);
    try {
      const userId = getUserId();
      const userRef = doc(db, 'users', userId);

      await runTransaction(db, async (transaction) => {
        const userDoc = await transaction.get(userRef);
        if (!userDoc.exists()) throw new Error("User does not exist");

        const data = userDoc.data();
        let updatedItems;
        
        switch(type) {
          case 'experience':
            updatedItems = data.experiences.filter((_, i) => i !== index);
            transaction.update(userRef, { experiences: updatedItems });
            setExperiences(updatedItems);
            break;
          case 'education':
            updatedItems = data.education.filter((_, i) => i !== index);
            transaction.update(userRef, { education: updatedItems });
            setEducation(updatedItems);
            break;
          case 'hobby':
            updatedItems = data.hobbies.filter((_, i) => i !== index);
            transaction.update(userRef, { hobbies: updatedItems });
            setHobbies(updatedItems);
            break;
        }
      });
    } catch (error) {
      console.error("Erreur lors de la suppression :", error);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour ouvrir le modal
  const handleModalOpen = (type, index = null) => {
    setModalType(type);
    setSelectedItem(index);
    
    if (index !== null) {
      if (type === 'experience') {
        setExperience(experiences[index]);
      } else if (type === 'education') {
        setNewEducation(education[index]);
      }
    }
    
    setShowModal(true);
  };

  const handleAddExperience = () => {
    setSelectedExperience(null);
    setShowModal(true);
  };

  const handleEditExperience = (experience) => {
    setSelectedExperience(experience);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedExperience(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      role: formData.get('role'),
      companyName: formData.get('companyName'),
      startDate: formData.get('startDate'),
      endDate: formData.get('endDate') || null,
      location: formData.get('location'),
      description: formData.get('description'),
      updatedAt: serverTimestamp()
    };

    try {
      const userId = getUserId();
      if (!userId) return;

      setLoading(true);
      const experiencesRef = collection(db, 'users', userId, 'experiences');

      if (selectedExperience?.id) {
        // Mise à jour
        const experienceDoc = doc(db, 'users', userId, 'experiences', selectedExperience.id);
        await updateDoc(experienceDoc, data);
        setExperiences(prevExperiences => 
          prevExperiences.map(exp => 
            exp.id === selectedExperience.id ? { ...exp, ...data } : exp
          )
        );
      } else {
        // Création
        const docRef = await addDoc(experiencesRef, {
          ...data,
          createdAt: serverTimestamp()
        });
        setExperiences(prevExperiences => [...prevExperiences, { id: docRef.id, ...data }]);
      }

      // Mettre à jour le statut de complétion
      await updateCompletionStatus();

      handleCloseModal();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (experience) => {
    setExperienceToDelete(experience);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!experienceToDelete) return;
    
    try {
      const userId = getUserId();
      if (!userId) return;

      setLoading(true);
      await deleteDoc(doc(db, `users/${userId}/experiences/${experienceToDelete.id}`));
      await fetchExperiences(); // Recharger les expériences après la suppression
      setShowDeleteModal(false);
      setExperienceToDelete(null);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'expérience:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteExperience = async () => {
    if (!selectedExperience) return;
    
    try {
      const userId = getUserId();
      if (!userId) return;

      setLoading(true);
      await deleteDoc(doc(db, `users/${userId}/experiences/${selectedExperience.id}`));
      await fetchExperiences(userId);
      
      // Mettre à jour le statut de complétion
      await updateCompletionStatus();

      setShowDeleteModal(false);
      setSelectedExperience(null);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'expérience:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEducation = async () => {
    if (!educationToDelete) return;
    
    try {
      const userId = getUserId();
      if (!userId) return;

      setLoadingEducation(true);
      await deleteDoc(doc(db, `users/${userId}/education/${educationToDelete.id}`));
      await fetchEducation(userId);
      
      // Mettre à jour le statut de complétion
      await updateCompletionStatus();

      setShowDeleteEducationModal(false);
      setEducationToDelete(null);
    } catch (error) {
      console.error("Erreur lors de la suppression de la formation:", error);
    } finally {
      setLoadingEducation(false);
    }
  };

  const handleEditEducation = (education) => {
    setSelectedEducation(education);
    setNewEducation({
      graduationYear: education.graduationYear,
      schoolName: education.schoolName,
      diploma: education.diploma,
      city: education.city
    });
    setShowEducationModal(true);
  };

  const handleSubmitEducation = async () => {
    setLoadingEducation(true);
    try {
      const userId = getUserId();
      if (!userId) return;

      if (selectedEducation) {
        // Modification d'une formation existante
        const educationRef = doc(db, 'users', userId, 'education', selectedEducation.id);
        await updateDoc(educationRef, {
          ...newEducation,
          timestamp: serverTimestamp()
        });
      } else {
        // Création d'une nouvelle formation
        const educationRef = collection(db, 'users', userId, 'education');
        await addDoc(educationRef, {
          ...newEducation,
          timestamp: serverTimestamp()
        });
      }

      // Mettre à jour le statut de complétion
      await updateCompletionStatus();

      // Recharger les formations
      await fetchEducation();
      setShowEducationModal(false);
      setNewEducation({
        graduationYear: '',
        schoolName: '',
        diploma: '',
        city: ''
      });
      setSelectedEducation(null);
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de la formation:', error);
    } finally {
      setLoadingEducation(false);
    }
  };

  const openEducationModal = () => {
    setSelectedEducation(null);
    setNewEducation({
      graduationYear: '',
      schoolName: '',
      diploma: '',
      city: ''
    });
    setShowEducationModal(true);
  };

  const handleCloseEducationModal = () => {
    setShowEducationModal(false);
    setSelectedEducation(null);
  };

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  // Fonction pour gérer le dépôt de CV
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      setUploadError("Le fichier est trop volumineux. Taille maximum : 5MB");
      toast.error("Le fichier est trop volumineux");
      return;
    }

    setIsUploading(true);
    setUploadError(null);
    setProcessingCV(true);

    try {
      // Étape 1: Upload
      updateProcessingStep('upload', 'loading');
      const reader = new FileReader();
      
      reader.onload = async () => {
        try {
          const base64String = reader.result.split(',')[1];
          updateProcessingStep('upload', 'completed');
          
          // Étape 2: Extraction
          updateProcessingStep('extraction', 'loading');
          const response = await fetch('https://processcv-lyrtmvchiq-od.a.run.app/processCV', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              base64Image: base64String,
              userId: auth.currentUser.uid,
              fileType: file.type,
            }),
          });

          const data = await response.json();
          
          if (!response.ok) {
            throw new Error(data.message || 'Erreur lors du traitement du CV');
          }

          updateProcessingStep('extraction', 'completed');
          
          // Étape 3: Analyse
          updateProcessingStep('analysis', 'loading');
          if (!data.experiences || !Array.isArray(data.experiences)) {
            throw new Error('Format de données invalide reçu du serveur');
          }
          updateProcessingStep('analysis', 'completed');
          
          // Étape 4: Sauvegarde
          updateProcessingStep('saving', 'loading');
          // Rafraîchir les données
          if (auth.currentUser) {
            await Promise.all([
              fetchExperiences(),
              fetchEducation(),
              fetchSkills(),
              fetchInterests()
            ]);
            updateProcessingStep('saving', 'completed');
            
            // Attendre un peu avant de fermer le modal pour montrer que tout est complété
            setTimeout(() => {
              setProcessingCV(false);
              setProcessingSteps({
                upload: { status: 'pending', label: 'Téléchargement du CV' },
                extraction: { status: 'pending', label: 'Extraction du texte' },
                analysis: { status: 'pending', label: 'Analyse du contenu' },
                saving: { status: 'pending', label: 'Sauvegarde des données' }
              });
            }, 1000);
            
            toast.success('CV traité avec succès !');
          } else {
            throw new Error('Utilisateur non authentifié');
          }
        } catch (error) {
          console.error('Error in reader.onload:', error);
          throw error;
        }
      };

      reader.onerror = () => {
        throw new Error('Erreur lors de la lecture du fichier');
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error processing CV:', error);
      setUploadError(error.message || 'Erreur lors du traitement du CV');
      toast.error(error.message || 'Erreur lors du traitement du CV');
      // Marquer l'étape actuelle comme échouée
      Object.keys(processingSteps).forEach(step => {
        if (processingSteps[step].status === 'loading') {
          updateProcessingStep(step, 'error');
        }
      });
      
      // En cas d'erreur, attendre un peu avant de fermer le modal
      setTimeout(() => {
        setProcessingCV(false);
        setProcessingSteps({
          upload: { status: 'pending', label: 'Téléchargement du CV' },
          extraction: { status: 'pending', label: 'Extraction du texte' },
          analysis: { status: 'pending', label: 'Analyse du contenu' },
          saving: { status: 'pending', label: 'Sauvegarde des données' }
        });
      }, 2000);
    } finally {
      setIsUploading(false);
    }
  }, [fetchExperiences, fetchEducation, fetchSkills, fetchInterests]);

  // Configuration de la zone de drop
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg']
    },
    maxFiles: 1,
    multiple: false
  });

  // Fonction pour gérer les suppressions
  const handleRemoveFile = () => {
    setUploadedFile(null);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  const updateCompletionStatus = useCallback(async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;
  
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) return;
  
      const updates = {};
  
      // Récupération du nombre de docs dans les sous-collections
      const experiencesSnapshot = await getDocs(collection(db, `users/${userId}/experiences`));
      const educationSnapshot   = await getDocs(collection(db, `users/${userId}/education`));
      const skillsSnapshot      = await getDocs(collection(db, `users/${userId}/skills`));
      const interestsSnapshot   = await getDocs(collection(db, `users/${userId}/interests`));
      
      // Calcul booléens
      updates.experiencesCompleted = experiencesSnapshot.size > 0;
      updates.educationCompleted   = educationSnapshot.size > 0;
      updates.skillsCompleted      = skillsSnapshot.size > 0;
      updates.interestsCompleted   = interestsSnapshot.size > 0;
  
      // Écriture en Firestore
      await updateDoc(userRef, updates);
  
      // Mettre à jour l'état local user pour refléter les nouvelles valeurs
      setUser(prevUser => ({
        ...prevUser,
        ...updates
      }));
  
      // OU Optionnel : refetch le doc pour être sûr
      // const newDoc = await getDoc(userRef);
      // if (newDoc.exists()) {
      //   setUser(prevUser => ({
      //     ...prevUser,
      //     ...newDoc.data()
      //   }));
      // }
      
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut de complétion:", error);
    }
  }, []);
  

  // Effet pour charger les données initiales
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) return;
    
        // 1) Charger toutes les sous-collections
        await Promise.all([
          fetchExperiences(),
          fetchEducation(),
          fetchSkills(),
          fetchInterests()
        ]);
    
        // 2) Mettre à jour le statut => corrige doc principal si besoin
        await updateCompletionStatus();
    
        // 3) Maintenant relire le doc principal (à jour)
        const userRef = doc(db, "users", userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUser(prevUser => ({
            ...prevUser,
            ...userData
          }));
        }
    
        setUserLoaded(true);
  
      } catch (error) {
        console.error("Erreur lors du chargement initial:", error);
      }
    };

    loadInitialData();
  }, [auth.currentUser]);

  // Mettre à jour le statut de complétion après chaque modification
  useEffect(() => {
    updateCompletionStatus();
  }, [experiences.length, education.length, skills.length, interests.length]);

  // Fonction pour vérifier si le profil est complet
  const isProfileComplete = useCallback(() => {
    return user.experiencesCompleted && 
           user.educationCompleted && 
           user.skillsCompleted && 
           user.interestsCompleted;
  }, [user.experiencesCompleted, user.educationCompleted, user.skillsCompleted, user.interestsCompleted]);

// Après (exemple)
const handleAddInterest = async () => {
  if (!newInterest.trim()) return;

  try {
    setLoading(true);
    const userId = getUserId();
    if (!userId) return;

    const interestsRef = collection(db, `users/${userId}/interests`);

    await runTransaction(db, async (transaction) => {
      // 1) Ajouter le nouvel intérêt dans la sous-collection
      await addDoc(interestsRef, {
        name: newInterest.trim(),
        timestamp: serverTimestamp(),
      });

      // 2) Optionnel : Mettre à jour l'état local pour refléter la sous-collection
      setInterests((prevInterests) => [
        ...prevInterests,
        { name: newInterest.trim(), timestamp: new Date().toISOString() },
      ]);
    });

    setNewInterest('');
    setShowInterestModal(false);

    // Mettre à jour le statut si nécessaire
    await updateCompletionStatus();
  } catch (error) {
    console.error("Erreur lors de l'ajout du centre d'intérêt :", error);
  } finally {
    setLoading(false);
  }
};


  // Fonction pour supprimer un centre d'intérêt
  const handleDeleteInterest = async (interestId) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      await deleteDoc(doc(db, `users/${userId}/interests/${interestId}`));
      await fetchInterests();
      await updateCompletionStatus();
    } catch (error) {
      console.error('Erreur lors de la suppression du centre interêt:', error);
    }
  };


  // Fonction pour ajouter une compétence
  const handleAddSkill = async () => {
    if (!newSkill.trim()) return;

    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const skillsRef = collection(db, `users/${userId}/skills`);
      await addDoc(skillsRef, {
        name: newSkill.trim(),
        timestamp: serverTimestamp()
      });

      setNewSkill('');
      setShowSkillModal(false);
      await fetchSkills();
      await updateCompletionStatus();
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la compétence:', error);
    }
  };

  // Fonction pour supprimer une compétence
  const handleDeleteSkill = async (skillId) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      await deleteDoc(doc(db, `users/${userId}/skills/${skillId}`));
      await fetchSkills();
      await updateCompletionStatus();
    } catch (error) {
      console.error('Erreur lors de la suppression de la compétence:', error);
    }
  };

  const handleDeleteEducationClick = (education) => {
    setEducationToDelete(education);
    setShowDeleteEducationModal(true);
  };

  if (checkingSession) {
    return <div>Chargement de la session...</div>;
  }

  // Vérifier si toutes les sections sont complétées
  const allSectionsCompleted = user ? (
    user.experiencesCompleted && 
    user.educationCompleted && 
    user.skillsCompleted && 
    user.interestsCompleted
  ) : false;
  return (
    <div className="min-h-screen bg-gray-50">
      <NavBar user={user} onLogout={handleLogout} />
      {/* Toaster pour les notifications */}
      <Toaster position="top-right" />
      
      {/* Zone de prolongement de la navbar */}
      <div className="bg-blue-500 h-40">
        {/* Section header */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8">
          <div className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-500 rounded-2xl p-8">
            <h2 className="text-3xl font-bold mb-2">
              <span className="text-white">Vos expériences professionnelles</span>
            </h2>
          </div>
        </div>
      </div>

      {/* Main content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-12">
        {/* Loader global */}
        {isUploading && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 max-w-sm w-full mx-4">
              <div className="flex flex-col items-center">
                <div className="relative">
                  <div className="w-16 h-16 border-4 border-blue-200 rounded-full"></div>
                  <div className="absolute top-0 left-0 w-16 h-16 border-4 border-blue-500 rounded-full animate-spin border-t-transparent"></div>
                </div>
                <div className="mt-4 text-center">
                  <p className="text-lg font-semibold text-gray-900">Traitement en cours...</p>
                  <p className="text-sm text-gray-500 mt-2">Nous analysons votre CV et extrayons les informations pertinentes.</p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Colonne de gauche (2/3) */}
          <div className="md:col-span-2">
          {userLoaded && isProfileComplete() && (
            <motion.div
              className="bg-gradient-to-br from-indigo-500 to-indigo-600 rounded-2xl shadow-lg overflow-hidden mb-8 transform hover:scale-[1.02] transition-all duration-300 border border-indigo-400/30"
            >
              <div className="relative p-6">
                <div className="absolute top-0 right-0 w-32 h-32 transform translate-x-16 -translate-y-16">
                  <div className="absolute inset-0 bg-indigo-400 opacity-20 rounded-full blur-3xl"></div>
                </div>
                
                <div className="relative z-10 flex justify-between items-center">
                  <div className="flex-1">
                    <div className="flex items-center space-x-3 mb-2">
                      <div className="p-1.5 bg-white/10 rounded-lg">
                        <SparklesIcon className="h-5 w-5 text-white" />
                      </div>
                      <h2 className="text-base font-semibold text-white">Améliorez votre profil</h2>
                    </div>
                    
                    <p className="text-white/90 text-sm leading-relaxed pr-4 max-w-2xl">
                      Félicitations ! Votre profil est maintenant complet.<br></br> Vérifiez que les informations extraites sont correctes puis procédez à l'amélioration de votre CV !
                    </p>
                  </div>
                  
                  <Link
                    to="/dashboard"
                    className="group inline-flex items-center justify-center rounded-lg text-sm font-semibold py-2 px-4 bg-white text-indigo-600 hover:bg-indigo-50 transition-all duration-200 shadow-md hover:shadow-lg whitespace-nowrap ml-4"
                  >
                    Aller à l'accueil
                    <ArrowLongRightIcon className="h-4 w-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-200" />
                  </Link>
                </div>
              </div>
            </motion.div>
          )}
            {/* Upload zone */}
            {userLoaded && !allSectionsCompleted && (
              <motion.div
                className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden mb-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="p-6">
                  <div className="flex items-center space-x-3 mb-4">
                    <DocumentArrowUpIcon className="h-6 w-6 text-blue-500" />
                    <h2 className="text-lg font-semibold text-gray-900">Import automatique de CV</h2>
                  </div>

                  <div
                    {...getRootProps()}
                    className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
                      ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-500'}
                      ${isUploading ? 'cursor-not-allowed' : ''}
                    `}
                  >
                    <input {...getInputProps()} disabled={isUploading} />
                    
                    {isUploading ? (
                      <div className="space-y-4">
                        <div className="flex justify-center">
                          <div className="relative">
                            <div className="w-12 h-12 border-4 border-blue-200 rounded-full"></div>
                            <div className="absolute top-0 left-0 w-12 h-12 border-4 border-blue-500 rounded-full animate-spin border-t-transparent"></div>
                          </div>
                        </div>
                        <p className="text-sm text-gray-600">Traitement du CV en cours...</p>
                      </div>
                    ) : (
                      <>
                        <DocumentArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <p className="mt-2 text-sm text-gray-600">
                          {isDragActive
                            ? "Déposez votre CV ici..."
                            : "Glissez-déposez votre CV ici, ou cliquez pour sélectionner un fichier"}
                        </p>
                        <p className="text-xs text-gray-500 mt-1">
                          PDF, PNG ou JPEG acceptés
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </motion.div>
            )}
            {/* Expériences professionnelles */}
            <motion.div 
              className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden mt-8"
              animate={{ 
                opacity: 1,
                y: allSectionsCompleted ? 0 : (isUploadExpanded ? 0 : -8),
                marginTop: allSectionsCompleted ? 0 : (isUploadExpanded ? '2rem' : '0')
              }}
              transition={{ duration: 0.25, ease: [0.4, 0, 0.2, 1] }}
            >
              <div className="p-6">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-lg font-semibold text-gray-900">Expériences professionnelles</h2>
                  <button
                    onClick={handleAddExperience}
                    className="inline-flex items-center justify-center rounded-lg text-sm font-semibold py-2 px-3 bg-blue-600 text-white hover:bg-blue-500 transition-all duration-200"
                  >
                    <PlusIcon className="h-4 w-4 mr-1" />
                    Ajouter
                  </button>
                </div>
                <div className="space-y-4">
                  {loading ? (
                    <div className="flex justify-center py-8">
                      <div className="animate-spin rounded-full h-8 w-8 border-2 border-blue-500 border-t-transparent"></div>
                    </div>
                  ) : experiences.length > 0 ? (
                    experiences.map((item) => (
                      <div key={item.id} className="flex flex-col p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition duration-200">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-4">
                            <div className="w-10 h-10 bg-blue-500 rounded-xl flex items-center justify-center text-white">
                              <BriefcaseIcon className="h-6 w-6" />
                            </div>
                            <div className="flex-1">
                              <div className="flex items-center space-x-2">
                                <h3 className="font-medium text-gray-900">{item.role}</h3>
                                <span className="text-gray-500">•</span>
                                <p className="text-gray-700">{item.companyName}</p>
                              </div>
                              <div className="flex items-center mt-1 text-sm text-gray-500">
                                <span>
                                  {new Date(item.startDate).toLocaleDateString('fr-FR', { month: 'short', year: 'numeric' })} - {
                                    item.endDate 
                                      ? new Date(item.endDate).toLocaleDateString('fr-FR', { month: 'short', year: 'numeric' })
                                      : 'Présent'
                                  }
                                </span>
                                {item.location && (
                                  <>
                                    <span className="mx-2">•</span>
                                    <span>{item.location}</span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="flex space-x-2">
                            <button
                              onClick={() => handleEditExperience(item)}
                              className="text-gray-400 hover:text-gray-500 p-2 hover:bg-gray-200 rounded-lg transition-all duration-200"
                            >
                              <PencilIcon className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleDeleteClick(item)}
                              className="text-gray-400 hover:text-red-500 p-2 hover:bg-red-50 rounded-lg transition-all duration-200"
                            >
                              <TrashIcon className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                        {item.description && (
                          <p className="mt-3 text-sm text-gray-600 whitespace-pre-line">
                            {item.description}
                          </p>
                        )}

                        {/* Hard Skills */}
                        {item.points_forts?.hard_skills && item.points_forts.hard_skills.length > 0 && (
                          <div className="mt-4">
                            <h4 className="text-sm font-medium text-gray-900 mb-2">Hard Skills</h4>
                            <div className="flex flex-wrap gap-2">
                              {item.points_forts.hard_skills.map((skill, index) => (
                                <span
                                  key={index}
                                  className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${getColorFromString(skill)} bg-opacity-10 text-${getColorFromString(skill)?.split('-')[1] || 'gray'}-700`}
                                >
                                  {skill}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                        
                        {/* Soft Skills */}
                        {item.points_forts?.soft_skills && item.points_forts.soft_skills.length > 0 && (
                          <div className="mt-3">
                            <h4 className="text-sm font-medium text-gray-900 mb-2">Soft Skills</h4>
                            <div className="flex flex-wrap gap-2">
                              {item.points_forts.soft_skills.map((skill, index) => (
                                <span
                                  key={index}
                                  className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${getColorFromString(skill)} bg-opacity-10 text-${getColorFromString(skill)?.split('-')[1] || 'gray'}-700`}
                                >
                                  {skill}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="text-center py-8">
                      <BriefcaseIcon className="mx-auto h-12 w-12 text-gray-300" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">Aucune expérience</h3>
                      <p className="mt-1 text-sm text-gray-500">Commencez par ajouter votre première expérience professionnelle.</p>
                      <div className="mt-6">
                        <button
                          onClick={handleAddExperience}
                          className="inline-flex items-center justify-center rounded-lg text-sm font-semibold py-2 px-4 bg-blue-600 text-white hover:bg-blue-500 transition-all duration-200"
                        >
                          <PlusIcon className="h-4 w-4 mr-1" />
                          Nouvelle expérience
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </div>

          {/* Colonne de droite (1/3) */}
          <div className="md:col-span-1 space-y-6">
            {/* Section Formation */}
            <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
              <div className="p-6">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-lg font-semibold text-gray-900">Études</h2>
                  <button
                    onClick={openEducationModal}
                    className="inline-flex items-center justify-center rounded-lg text-sm font-semibold py-2 px-3 bg-blue-600 text-white hover:bg-blue-500 transition-all duration-200"
                  >
                    <PlusIcon className="h-4 w-4 mr-1" />
                    Ajouter
                  </button>
                </div>
                <div className="space-y-4">
                  {loadingEducation ? (
                    <div className="flex justify-center py-8">
                      <div className="animate-spin rounded-full h-8 w-8 border-2 border-blue-500 border-t-transparent"></div>
                    </div>
                  ) : education.length > 0 ? (
                    education.map((item) => (
                      <div key={item.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition duration-200">
                        <div className="flex items-center space-x-4">
                          <div className="w-10 h-10 bg-blue-500 rounded-xl flex items-center justify-center text-white">
                            <AcademicCapIcon className="h-6 w-6" />
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-900">{truncateText(item.diploma, 18)}</h3>
                            <p className="text-sm text-gray-500">{item.schoolName}</p>
                            <div className="flex items-center mt-1 text-sm text-gray-500">
                              <span>Obtenu en {item.graduationYear || 'Non spécifié'}</span>
                            </div>
                          </div>
                        </div>
                        <div className="flex space-x-2">
                          <button
                            onClick={() => handleEditEducation(item)}
                            className="text-gray-400 hover:text-gray-500 p-2 hover:bg-gray-200 rounded-lg transition-all duration-200"
                          >
                            <PencilIcon className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteEducationClick(item)}
                            className="text-gray-400 hover:text-red-500 p-2 hover:bg-red-50 rounded-lg transition-all duration-200"
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center py-8">
                      <AcademicCapIcon className="mx-auto h-12 w-12 text-gray-300" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">Aucune formation</h3>
                      <p className="mt-1 text-sm text-gray-500">Commencez par ajouter votre première formation.</p>
                      <div className="mt-6">
                        <button
                          onClick={openEducationModal}
                          className="inline-flex items-center justify-center rounded-lg text-sm font-semibold py-2 px-4 bg-blue-600 text-white hover:bg-blue-500 transition-all duration-200"
                        >
                          <PlusIcon className="h-4 w-4 mr-1" />
                          Nouvelle formation
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Section Compétences */}
            <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
              <div className="p-6">
                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center">
                    <h2 className="text-lg font-semibold text-gray-900">Compétences</h2>
                  </div>
                  <button
                    onClick={() => setShowSkillModal(true)}
                    className="inline-flex items-center justify-center rounded-lg text-sm font-semibold py-2 px-3 bg-blue-600 text-white hover:bg-blue-500 transition-all duration-200"
                  >
                    <PlusIcon className="h-4 w-4 mr-1" />
                    Ajouter
                  </button>
                </div>
                <div className="flex flex-wrap gap-2">
                  {skills.map((skill, index) => (
                    <div
                      key={index}
                      className={`group relative inline-flex items-center px-3 py-1 rounded-lg text-sm font-medium ${getColorFromString(skill.name)} bg-opacity-10 text-${getColorFromString(skill.name)?.split('-')[1] || 'gray'}-700`}
                    >
                      {skill.name}
                      <button
                        onClick={() => handleDeleteSkill(skill.id)}
                        className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity text-gray-400 hover:text-red-500"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                  {skills.length === 0 && (
                    <p className="text-sm text-gray-500">Aucune compétence ajoutée.</p>
                  )}
                </div>
              </div>
            </div>

            {/* Section Centres d'intérêts */}
            <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
              <div className="p-6">
                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center">
                    <h2 className="text-lg font-semibold text-gray-900">Centres d'intérêts</h2>
                  </div>
                  <button
                    onClick={() => setShowInterestModal(true)}
                    className="inline-flex items-center justify-center rounded-lg text-sm font-semibold py-2 px-3 bg-blue-600 text-white hover:bg-blue-500 transition-all duration-200"
                  >
                    <PlusIcon className="h-4 w-4 mr-1" />
                    Ajouter
                  </button>
                </div>
                <div className="flex flex-wrap gap-2">
                  {interests.map((interest, index) => (
                    <div
                      key={index}
                      className={`group relative inline-flex items-center px-3 py-1 rounded-lg text-sm font-medium ${getColorFromString(interest.name)} bg-opacity-10 text-${getColorFromString(interest.name)?.split('-')[1] || 'gray'}-700`}
                    >
                      {interest.name}
                      <button
                        onClick={() => handleDeleteInterest(interest.id)}
                        className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity text-gray-400 hover:text-red-500"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                  {interests.length === 0 && (
                    <p className="text-sm text-gray-500">Aucun centre d'intérêt ajouté.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Modal pour ajouter/éditer */}
      {showModal && (
        <div 
          className="fixed inset-0 z-50 overflow-y-auto"
          aria-labelledby="modal-title" 
          role="dialog" 
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            {/* Overlay */}
            <div 
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-out duration-300"
              aria-hidden="true"
              onClick={() => setShowModal(false)}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            {/* Trick pour centrer */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

            <motion.div 
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
              className="relative inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full animate-fade-in-up"
            >
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => setShowModal(false)}
                >
                  <span className="sr-only">Fermer</span>
                  <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="bg-white p-6 sm:p-10">
                <div className="mx-auto max-w-2xl">
                  <h3 className="mb-6 text-center text-2xl font-bold text-gray-800 sm:text-left">
                    {selectedExperience ? 'Modifier l\'expérience' : 'Ajouter une expérience'}
                  </h3>

                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                        Poste
                      </label>
                      <input
                        type="text"
                        name="role"
                        id="role"
                        defaultValue={selectedExperience?.role || ''}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">
                        Entreprise
                      </label>
                      <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        defaultValue={selectedExperience?.companyName || ''}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                        Lieu
                      </label>
                      <input
                        type="text"
                        name="location"
                        id="location"
                        defaultValue={selectedExperience?.location || ''}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                          Date de début
                        </label>
                        <input
                          type="date"
                          name="startDate"
                          id="startDate"
                          defaultValue={selectedExperience?.startDate || ''}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                          Date de fin
                        </label>
                        <input
                          type="date"
                          name="endDate"
                          id="endDate"
                          defaultValue={selectedExperience?.endDate || ''}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        rows="4"
                        defaultValue={selectedExperience?.description || ''}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                      ></textarea>
                    </div>

                    <div className="mt-6 flex justify-end space-x-3">
                      <button
                        type="button"
                        onClick={handleCloseModal}
                        className="rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Annuler
                      </button>
                      <button
                        type="submit"
                        className="rounded-lg bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        {selectedExperience ? 'Modifier' : 'Ajouter'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      )}
      {/* Modal de confirmation de suppression */}
      {showDeleteModal && (
        <div 
          className="fixed inset-0 z-[60] overflow-y-auto"
          aria-labelledby="modal-title" 
          role="dialog" 
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            {/* Overlay */}
            <div 
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-out duration-300"
              aria-hidden="true"
              onClick={() => setShowModal(false)}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            {/* Trick pour centrer */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

            <motion.div 
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
              className="relative inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full animate-fade-in-up"
            >
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => setShowModal(false)}
                >
                  <span className="sr-only">Fermer</span>
                  <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="bg-white p-6 sm:p-10">
                <div className="mx-auto max-w-2xl">
                  <h3 className="mb-6 text-center text-2xl font-bold text-gray-800 sm:text-left">
                    Supprimer l'expérience
                  </h3>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Êtes-vous sûr de vouloir supprimer cette expérience ? Cette action est irréversible.
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleConfirmDelete}
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-lg shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Supprimer
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setShowDeleteModal(false);
                    setExperienceToDelete(null);
                  }}
                  className="mt-3 w-full inline-flex justify-center rounded-lg border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Annuler
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      )}
      {/* Modal de suppression de formation */}
      {showDeleteEducationModal && (
        <div 
          className="fixed inset-0 z-[60] overflow-y-auto"
          aria-labelledby="modal-title" 
          role="dialog" 
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            {/* Overlay */}
            <div 
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-out duration-300"
              aria-hidden="true"
              onClick={() => setShowModal(false)}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            {/* Trick pour centrer */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

            <motion.div 
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
              className="relative inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full animate-fade-in-up"
            >
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => setShowModal(false)}
                >
                  <span className="sr-only">Fermer</span>
                  <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="bg-white p-6 sm:p-10">
                <div className="mx-auto max-w-2xl">
                  <h3 className="mb-6 text-center text-2xl font-bold text-gray-800 sm:text-left">
                    Supprimer la formation
                  </h3>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Êtes-vous sûr de vouloir supprimer cette formation ? Cette action est irréversible.
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleDeleteEducation}
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-lg shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Supprimer
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setShowDeleteEducationModal(false);
                    setEducationToDelete(null);
                  }}
                  className="mt-3 w-full inline-flex justify-center rounded-lg border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Annuler
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      )}
      {/* Modal d'ajout/modification de formation */}
      {showEducationModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
              className="relative inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {selectedEducation ? 'Modifier la formation' : 'Ajouter une formation'}
                    </h3>

                    <div className="mt-4 space-y-4">
                      <div>
                        <label htmlFor="diploma" className="block text-sm font-medium text-gray-700">
                          Diplôme
                        </label>
                        <input
                          type="text"
                          name="diploma"
                          id="diploma"
                          value={newEducation.diploma}
                          onChange={(e) => setNewEducation({ ...newEducation, diploma: e.target.value })}
                          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                      </div>
                      <div>
                        <label htmlFor="schoolName" className="block text-sm font-medium text-gray-700">
                          École
                        </label>
                        <input
                          type="text"
                          name="schoolName"
                          id="schoolName"
                          value={newEducation.schoolName}
                          onChange={(e) => setNewEducation({ ...newEducation, schoolName: e.target.value })}
                          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                      </div>
                      <div>
                        <label htmlFor="graduationYear" className="block text-sm font-medium text-gray-700">
                          Année d'obtention
                        </label>
                        <input
                          type="text"
                          name="graduationYear"
                          id="graduationYear"
                          value={newEducation.graduationYear}
                          onChange={(e) => setNewEducation({ ...newEducation, graduationYear: e.target.value })}
                          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                      </div>
                      <div>
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                          Ville
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          value={newEducation.city}
                          onChange={(e) => setNewEducation({ ...newEducation, city: e.target.value })}
                          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleSubmitEducation}
                  className="w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {selectedEducation ? 'Modifier' : 'Ajouter'}
                </button>
                <button
                  type="button"
                  onClick={handleCloseEducationModal}
                  className="mt-3 w-full inline-flex justify-center rounded-lg border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Annuler
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      )}
      {/* Modal pour les centres d'intérêts */}
      {showInterestModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
              className="relative inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Ajouter un centre d'intérêt
                    </h3>
                    <div className="mt-4">
                      <input
                        type="text"
                        value={newInterest}
                        onChange={(e) => setNewInterest(e.target.value)}
                        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        placeholder="Entrez un centre d'intérêt"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleAddInterest}
                  className="w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Ajouter
                </button>
                <button
                  type="button"
                  onClick={() => setShowInterestModal(false)}
                  className="mt-3 w-full inline-flex justify-center rounded-lg border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Annuler
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      )}

      {/* Modal pour les compétences */}
      {showSkillModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
              className="relative inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Ajouter une compétence
                    </h3>
                    <div className="mt-4">
                      <input
                        type="text"
                        value={newSkill}
                        onChange={(e) => setNewSkill(e.target.value)}
                        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        placeholder="Entrez une compétence"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleAddSkill}
                  className="w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Ajouter
                </button>
                <button
                  type="button"
                  onClick={() => setShowSkillModal(false)}
                  className="mt-3 w-full inline-flex justify-center rounded-lg border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Annuler
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      )}
      {/* Modal de traitement du CV */}
      {processingCV && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-white rounded-xl shadow-xl p-8 max-w-md w-full mx-4"
          >
            <h3 className="text-xl font-semibold text-gray-900 mb-6 text-center">
              Traitement de votre CV en cours
            </h3>
            <div className="space-y-6">
              {Object.entries(processingSteps).map(([key, { status, label }], index) => (
                <div key={key} className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    {status === 'completed' ? (
                      <motion.div
                        initial={{ scale: 0.5, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        className="w-6 h-6 rounded-full bg-green-500 flex items-center justify-center"
                      >
                        <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </motion.div>
                    ) : status === 'loading' ? (
                      <div className="w-6 h-6">
                        <motion.div
                          animate={{ rotate: 360 }}
                          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                          className="w-full h-full border-2 border-blue-500 border-t-transparent rounded-full"
                        />
                      </div>
                    ) : status === 'error' ? (
                      <div className="w-6 h-6 rounded-full bg-red-500 flex items-center justify-center">
                        <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                    ) : (
                      <div className="w-6 h-6 rounded-full border-2 border-gray-300" />
                    )}
                  </div>
                  <div className="flex-grow">
                    <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                      <motion.div
                        initial={{ width: "0%" }}
                        animate={{
                          width: status === 'completed' ? "100%" :
                                 status === 'loading' ? "60%" :
                                 status === 'error' ? "100%" : "0%"
                        }}
                        className={`h-full ${
                          status === 'completed' ? 'bg-green-500' :
                          status === 'loading' ? 'bg-blue-500' :
                          status === 'error' ? 'bg-red-500' :
                          'bg-gray-300'
                        }`}
                      />
                    </div>
                    <p className="text-sm font-medium text-gray-700 mt-1">{label}</p>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default Experiences;
