import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../services/firebase';
import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';
import { EyeIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid';

const Models = () => {
  const [checkingSession, setCheckingSession] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);

  // Données fictives de l'utilisateur
  const [userDoc, setUserDoc] = useState({
    firstName: 'John',
    lastName: 'Doe',
    address: '123 Rue Principale',
    city: 'Paris',
    country: 'France',
  });

  // Liste des LM (templates) récupérés depuis Firestore
  const [lmModels, setLmModels] = useState([]);
  // Modèle sélectionné pour la preview
  const [selectedModel, setSelectedModel] = useState(null);
  // Message de confirmation (toast)
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        navigate('/login');
      } else {
        setUser(currentUser);
        // Si besoin, tu peux récupérer ici le doc Firestore "users/{uid}" pour alimenter userDoc
      }
      setCheckingSession(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchLmModels = async () => {
      try {
        setIsLoading(true);
        const ref = collection(db, 'models-lm');
        const snapshot = await getDocs(ref);

        const fetched = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            name: `Modèle #${doc.id}`,
            // Suppose que doc.data() = { "1": "<!DOCTYPE html>..." }
            // On récupère le HTML via data[doc.id], ou data.html si ta structure est différente
            html: data[doc.id],
          };
        });

        setLmModels(fetched);
      } catch (error) {
        console.error('Erreur de récupération des LM:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLmModels();
  }, []);

  // Remplace dans le HTML les placeholders par les données du user
  const generatePreviewHtml = (rawHtml, userData) => {
    if (!rawHtml) return '';
    return rawHtml
      .replace(/PrenomNom/g, `${userData.firstName} ${userData.lastName}`)
      .replace(/AdresseUser/g, userData.address)
      .replace(/CityVille/g, `${userData.city} ${userData.country}`)
      .replace(
        /ContenuLM/g,
        `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Sed non risus. Suspendisse lectus tortor, dignissim sit amet,
adipiscing nec, ultricies sed, dolor.`
      );
  };

  const handlePreview = (model) => {
    setSelectedModel(model);
  };

  const handleSelect = async (model) => {
    if (!user) {
      alert('Vous devez être connecté pour sélectionner un modèle.');
      return;
    }

    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        lmChoix: model.id,
      });

      setConfirmationMessage(`Modèle #${model.id} sélectionné !`);
      setTimeout(() => {
        setConfirmationMessage('');
      }, 3000);
    } catch (error) {
      console.error('Erreur lors de la sélection du modèle:', error);
      alert('Une erreur est survenue lors de la sélection du modèle.');
    }
  };

  if (checkingSession) {
    return <div></div>;
  }

  return (
    <>
      {isLoading && <Loader />}

      <div
        className={`min-h-screen bg-gray-50 ${
          isLoading ? 'opacity-0' : 'opacity-100 transition-opacity duration-500'
        }`}
      >
        {/* Barre de navigation */}
        <NavBar user={user} onLogout={() => auth.signOut()} />

        {/* En-tête bleu */}
        <div className="bg-blue-500 h-40">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-12">
            <div className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-500 rounded-2xl p-8">
              <h2 className="text-3xl font-bold mb-4 text-white">
                Choisissez votre modèle de CV
              </h2>
            </div>
          </div>
        </div>

        {/* Contenu principal */}
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-6">
          <div className="grid grid-cols-1 md:grid-cols-10 gap-8">

            {/* 6/10 : Liste des modèles */}
            <div className="md:col-span-6 bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
              <div className="p-6">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-lg font-semibold text-gray-900">
                    Liste des Modèles
                  </h2>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    {lmModels.length} modèles
                  </span>
                </div>

                <div className="space-y-4">
                  {lmModels.map((model) => (
                    <div
                      key={model.id}
                      className="flex items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition duration-200"
                    >
                      <div>
                        <h3 className="font-medium text-gray-900">{model.name}</h3>
                        <p className="text-sm text-gray-500 mt-1">Modèle ID: {model.id}</p>
                      </div>

                      <button
                        onClick={() => handlePreview(model)}
                        className="flex items-center px-4 py-2 text-sm font-medium bg-blue-600 text-white hover:bg-blue-500 rounded-lg shadow transition"
                      >
                        <EyeIcon className="h-5 w-5 mr-2" />
                        Voir
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* 4/10 : Aperçu + bouton "Sélectionner" */}
            <div className="md:col-span-4 bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
              <div className="p-6 h-full flex flex-col">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-lg font-semibold text-gray-900">Aperçu</h2>
                </div>

                {selectedModel ? (
                  <>
                    {/*
                      La zone d'aperçu A4 :
                      - w-[210mm], h-[297mm] => ratio d'une page A4
                      - scale-[0.57] pour ~450 px de large, ~630 px de haut
                    */}
                    <div className="flex-1 flex justify-center overflow-auto border rounded-xl p-2">
                      <div
                        className="relative w-[210mm] h-[297mm] transform scale-[0.57] 
                                   transform-origin-top-center bg-white shadow-md"
                      >
                        <div
                          className="w-full h-full p-4"
                          dangerouslySetInnerHTML={{
                            __html: generatePreviewHtml(selectedModel.html, userDoc),
                          }}
                        />
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        onClick={() => handleSelect(selectedModel)}
                        className="bg-green-600 text-white px-6 py-3 rounded-xl font-medium hover:bg-green-500
                                   transition-all duration-300 flex items-center space-x-2 shadow-lg"
                      >
                        Sélectionner
                        <ArrowLongRightIcon className="h-5 w-5 ml-2" />
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="flex-1 flex items-center justify-center">
                    <p className="text-gray-500 text-sm">
                      Sélectionnez un modèle à gauche pour l’apercevoir ici
                    </p>
                  </div>
                )}
              </div>
            </div>

          </div>
        </main>

        {/* Notification de confirmation (toast) */}
        {confirmationMessage && (
          <div className="fixed bottom-4 right-4 px-4 py-3 bg-green-600 text-white rounded shadow-lg">
            {confirmationMessage}
          </div>
        )}
      </div>
    </>
  );
};

export default Models;
