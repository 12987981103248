import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, FileCheck, Layout, PenTool, Sparkles, Users } from 'lucide-react';
import PublicLayout from '../../layouts/PublicLayout';

const Home = () => {
  const navigate = useNavigate();

  const stats = [
    { number: "10,000+", label: "CV générés", icon: FileCheck },
    { number: "500+", label: "Entreprises", icon: Users },
    { number: "98%", label: "Satisfaction", icon: Sparkles }
  ];

  const features = [
    {
      icon: FileCheck,
      title: "CV Optimisé par l'IA",
      description: "Analyse et optimisation intelligente de chaque section de votre CV"
    },
    {
      icon: Layout,
      title: "Design Modern",
      description: "Templates professionnels adaptés aux standards 2024"
    },
    {
      icon: PenTool,
      title: "Lettre Personnalisée",
      description: "Lettres de motivation uniques générées en quelques secondes"
    }
  ];

  return (
    <PublicLayout>
      <div className="relative min-h-screen bg-gray-50">
        {/* Modern gradient background */}
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_#2663eb0d_0%,_transparent_60%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_#2663eb0d_0%,_transparent_60%)]" />
        </div>

        {/* Content */}
        <div className="relative z-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            {/* Hero Section */}
            <div className="grid lg:grid-cols-2 gap-12 items-center mb-24">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-center lg:text-left max-w-4xl mx-auto"
              >
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="inline-flex items-center px-4 py-1.5 rounded-full bg-blue-50 border border-blue-100 mb-8"
                >
                  <Sparkles className="w-4 h-4 text-[#2663eb] mr-2" />
                  <span className="text-sm font-medium text-[#2663eb]">
                    Intelligence Artificielle
                  </span>
                </motion.div>

                <h1 className="text-5xl lg:text-7xl font-bold tracking-tight mb-8">
                  Créez votre futur
                  <span className="block mt-2 text-[#2663eb]">
                    professionnel
                  </span>
                </h1>

                <p className="text-xl text-gray-600 mb-12 max-w-2xl">
                  CV et lettres de motivation optimisés par l'IA pour maximiser vos chances de réussite.
                </p>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => navigate('/login')}
                  className="group inline-flex items-center px-8 py-4 bg-[#2663eb] text-white rounded-2xl font-semibold shadow-lg hover:shadow-[#2663eb]/20 hover:bg-blue-700 transition-all gap-2"
                >
                  Démarrer gratuitement
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </motion.button>
              </motion.div>

              <div className="hidden lg:block">
                <motion.img
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&q=80"
                  alt="Professional CV"
                  className="rounded-2xl shadow-2xl"
                />
              </div>
            </div>

            {/* Stats Section */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-24">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="group p-8 rounded-2xl bg-white border border-gray-100 hover:border-[#2663eb]/20 shadow-sm hover:shadow-lg transition-all"
                >
                  <stat.icon className="w-8 h-8 text-[#2663eb] mb-4 group-hover:scale-110 transition-transform" />
                  <div className="font-bold text-4xl mb-2">{stat.number}</div>
                  <div className="text-gray-600">{stat.label}</div>
                </motion.div>
              ))}
            </div>

            {/* Features Section */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="group p-8 rounded-2xl bg-white border border-gray-100 hover:border-[#2663eb]/20 shadow-sm hover:shadow-lg transition-all"
                >
                  <div className="w-14 h-14 rounded-2xl bg-blue-50 mb-6 flex items-center justify-center group-hover:scale-110 transition-all">
                    <feature.icon className="w-7 h-7 text-[#2663eb]" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Home;