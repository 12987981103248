import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, query, orderBy, getDocs, updateDoc, onSnapshot } from 'firebase/firestore';
import { auth, db, functions } from '../../services/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { setPersistence, browserLocalPersistence } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PlusIcon,
  HomeIcon,
  AcademicCapIcon,
  CreditCardIcon,
  BookOpenIcon,
  MagnifyingGlassIcon, 
  ArrowLongRightIcon,
  DocumentTextIcon,
  DocumentDuplicateIcon,
  ClockIcon,
  CheckCircleIcon,
  SparklesIcon,
  LinkIcon,
  BriefcaseIcon,
  ChevronRightIcon,
  HeartIcon,
  LightBulbIcon,
  RocketLaunchIcon,
  BeakerIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { 
  SparklesIcon as SparklesSolidIcon, 
  MagnifyingGlassIcon as MagnifyingGlassSolidIcon
} from '@heroicons/react/24/solid';
import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Authentification configurée avec persistance locale
  })
  .catch((error) => {
    console.error("Erreur lors de la définition de la persistance :", error);
  });

const getColorFromString = (str) => {
  const colors = [
    'bg-red-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500', 
    'bg-pink-500', 'bg-indigo-500', 'bg-teal-500'
  ];
  
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
};

const formatDate = (timestamp) => {
  if (!timestamp) return '';
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
  return date.toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const getScoreColor = (score) => {
  if (score >= 80) return 'text-green-500';
  if (score >= 60) return 'text-blue-500';
  if (score >= 40) return 'text-yellow-500';
  return 'text-red-500';
};

const getScoreTrackColor = (score) => {
  if (score >= 80) return 'text-green-100';
  if (score >= 60) return 'text-blue-100';
  if (score >= 40) return 'text-yellow-100';
  return 'text-red-100';
};

const CircularProgress = ({ value }) => {
  const circumference = 2 * Math.PI * 20; // r = 20
  const strokeDashoffset = circumference - (value / 100) * circumference;
  const scoreColor = getScoreColor(value);
  const trackColor = getScoreTrackColor(value);

  return (
    <div className="relative w-14 h-14 flex items-center justify-center">
      <svg className="transform -rotate-90 w-14 h-14">
        <circle
          cx="28"
          cy="28"
          r="20"
          stroke="currentColor"
          strokeWidth="4"
          fill="transparent"
          className={trackColor}
        />
        <circle
          cx="28"
          cy="28"
          r="20"
          stroke="currentColor"
          strokeWidth="4"
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          className={`${scoreColor} transition-all duration-500 ease-out`}
        />
      </svg>
      <span className={`absolute text-sm font-medium ${scoreColor}`}>
        {value}
      </span>
    </div>
  );
};

const styles = `
  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    12.5% { transform: translateX(-4px); }
    37.5% { transform: translateX(4px); }
    62.5% { transform: translateX(-4px); }
    87.5% { transform: translateX(4px); }
  }

  .animate-shake {
    animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  }
`;

const styleSheet = document.createElement("style");
styleSheet.textContent = styles;
document.head.appendChild(styleSheet);

const Dashboard = () => {
  const [checkingSession, setCheckingSession] = useState(true);
  const [user, setUser] = useState(null);
  const [jobUrl, setJobUrl] = useState('');
  const [urlError, setUrlError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lmHistory, setLmHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [resourcesLoaded, setResourcesLoaded] = useState(false);
  const [hasJustCompleted, setHasJustCompleted] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [isImproving, setIsImproving] = useState(false);
  const [improvementProgress, setImprovementProgress] = useState(0);
  const [currentTip, setCurrentTip] = useState(0);
  const [suggestedJobs, setSuggestedJobs] = useState([]);
  const [analysisStatus, setAnalysisStatus] = useState('');
  const [analysisResult, setAnalysisResult] = useState(null);
  const [secondAssistantResult, setSecondAssistantResult] = useState(null);
  const [showAnalysisModal, setShowAnalysisModal] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [hasSuggestedJobs, setHasSuggestedJobs] = useState(false);
  const [jobData, setJobData] = useState(null);
  const [candidateData, setCandidateData] = useState(null);

  const isProfileComplete = completionPercentage === 100;

  const improvementTips = [
    "Analyse de vos expériences professionnelles...",
    "Identification des compétences clés...",
    "Enrichissement de votre profil...",
    "Optimisation des descriptions...",
    "Finalisation des améliorations..."
  ];

  useEffect(() => {
    if (isImproving) {
      const interval = setInterval(() => {
        setImprovementProgress(prev => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 1;
        });
        
        if (improvementProgress < 20) setCurrentTip(0);
        else if (improvementProgress < 40) setCurrentTip(1);
        else if (improvementProgress < 60) setCurrentTip(2);
        else if (improvementProgress < 80) setCurrentTip(3);
        else setCurrentTip(4);
      }, 150);

      return () => clearInterval(interval);
    }
  }, [isImproving, improvementProgress]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUser(userData);
          const percentage = Math.floor(
            25 * (
              (userData.experiencesCompleted ? 1 : 0) +
              (userData.educationCompleted ? 1 : 0) +
              (userData.interestsCompleted ? 1 : 0) +
              (userData.skillsCompleted ? 1 : 0)
            )
          );
          setCompletionPercentage(percentage);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setCheckingSession(false);
      }
    };

    if (auth.currentUser) {
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        try {
          user.getIdToken(true)
            .then((token) => {
              // fetchUserData(user.uid);
            })
            .catch((error) => {
              auth.signOut();
              navigate('/login');
            });
        } catch (error) {
          auth.signOut();
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const loadResources = async () => {
      try {
        await new Promise(resolve => {
          const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
              resolve();
            }
          });
        });

        await new Promise(resolve => setTimeout(resolve, 500));
        
        setResourcesLoaded(true);
      } catch (error) {
        console.error('Erreur lors du chargement des ressources:', error);
        setResourcesLoaded(true);
      }
    };

    loadResources();
  }, []);

  useEffect(() => {
    if (resourcesLoaded) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [resourcesLoaded]);

  const handleProfileImageLoad = () => {
    setResourcesLoaded(true);
  };

  useEffect(() => {
    if (isProfileComplete && !hasJustCompleted) {
      setHasJustCompleted(true);
    }
  }, [completionPercentage]);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisitedDashboard');
    if (hasVisited) {
      setIsFirstVisit(false);
    } else {
      localStorage.setItem('hasVisitedDashboard', 'true');
    }
  }, []);

  useEffect(() => {
    const fetchLmHistory = async () => {
      try {
        const userId = auth.currentUser?.uid;
        if (userId) {
          const lmHistoryRef = collection(db, 'users', userId, 'lmHistory');
          const q = query(lmHistoryRef, orderBy('createdAt', 'desc'));
          const querySnapshot = await getDocs(q);
          const historyData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setLmHistory(historyData);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'historique:', error);
      } finally {
        setLoadingHistory(false);
      }
    };

    if (auth.currentUser) {
      fetchLmHistory();
    }
  }, []);

  useEffect(() => {
    let unsubscribe; // Déclaration de unsubscribe

    const setupFirestoreListener = () => {
      if (auth.currentUser) {
        try {
          const suggestedJobsRef = collection(db, `users/${auth.currentUser.uid}/suggested_jobs`);
          
          unsubscribe = onSnapshot(suggestedJobsRef, (snapshot) => {
            setHasSuggestedJobs(!snapshot.empty);
            
            if (!snapshot.empty) {
              const jobs = [];
              snapshot.forEach((doc) => {
                jobs.push({ id: doc.id, ...doc.data() });
              });
              setSuggestedJobs(jobs);
            } else {
              setSuggestedJobs([]);
            }
          }, (error) => {
            console.error("Erreur lors du chargement des offres suggérées:", error);
            setHasSuggestedJobs(false);
          });
        } catch (error) {
          console.error("Erreur lors de l'initialisation du listener:", error);
        }
      }
    };

    const authUnsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setupFirestoreListener();
      }
    });
  
    return () => {
      if (unsubscribe) {
        unsubscribe(); // Nettoyage de l'écouteur
      }
      authUnsubscribe();
    };
  }, []);

  const handleSubmitJobUrl = async (e) => {
    e.preventDefault();
    console.log("1. [Dashboard] Début de handleSubmitJobUrl");
    
    setSecondAssistantResult(null);
    
    if (!jobUrl) {
      console.log("[Dashboard] URL manquante");
      return;
    }

    if (!auth.currentUser) {
      console.log("[Dashboard] Utilisateur non authentifié");
      setAnalysisStatus("Erreur : Vous devez être connecté pour analyser une offre");
      return;
    }

    console.log("2. [Dashboard] URL soumise:", jobUrl);
    console.log("3. [Dashboard] User ID:", auth.currentUser.uid);

    try {
      console.log("4. [Dashboard] Début de l'analyse");
      setIsAnalyzing(true);
      setAnalysisStatus("Analyse de l'offre d'emploi en cours... (cela peut prendre jusqu'à 3 minutes)");
      setShowAnalysisModal(true);
      
      console.log("5. [Dashboard] Préparation de l'appel à analyzeJobOffer");
      const analyzeJobOffer = httpsCallable(functions, "analyzeJobOffer");
      
      console.log("6. [Dashboard] Appel de la fonction avec les paramètres:", {
        jobUrl,
        userId: auth.currentUser.uid
      });
      
      const result = await analyzeJobOffer({ 
        jobUrl: jobUrl,
        userId: auth.currentUser.uid 
      });

      console.log("7. [Dashboard] Résultat reçu:", result);
      console.log("7bis. [Dashboard] Structure détaillée du résultat:", JSON.stringify(result.data, null, 2));

      setJobData(result.data.jobData);
      setCandidateData(result.data.candidateData);
      setAnalysisResult(result.data.analysisResult);
      setSecondAssistantResult(result.data.secondAssistantResult);
      
      setAnalysisStatus("Analyse terminée");
      setJobUrl("");
      setUrlError(false);
      
      console.log("8. [Dashboard] Analyse terminée avec succès");
    } catch (error) {
      console.error("9. [Dashboard] Erreur lors de l'analyse:", error);
      console.error("10. [Dashboard] Stack trace:", error.stack);
      
      let errorMessage = "Une erreur est survenue lors de l'analyse.";
      if (error.code === "deadline-exceeded") {
        errorMessage = "L'analyse a pris trop de temps. L'analyse prend généralement 2-3 minutes, veuillez réessayer.";
      } else if (error.code === "invalid-argument") {
        errorMessage = "L'URL fournie n'est pas valide. Veuillez vérifier le lien et réessayer.";
      }
      
      setAnalysisStatus(errorMessage);
      setUrlError(true);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  const handleImprovement = async () => {
    try {
      const userId = auth.currentUser.uid;
      if (!userId) return;
  
      setIsImproving(true);
      setImprovementProgress(0);
  
      const response = await fetch('https://improveprofile-lyrtmvchiq-od.a.run.app/improveProfile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: { userId } }), // Modification ici
      });
  
      if (!response.ok) {
        throw new Error('Erreur lors de l\'amélioration du profil');
      }

      // Attendre la fin de l'amélioration (vous pouvez ajuster selon vos besoins)
      await new Promise(resolve => setTimeout(resolve, 2000));
  
      const result = await response.json();
      console.log('Profil amélioré avec succès:', result);
      
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        skillsCheck: true
      });
      
      // Mettre à jour l'état pour indiquer que l'amélioration est terminée
      setIsImproving(false);
    } catch (error) {
      console.error('Erreur:', error);
      alert('Une erreur est survenue lors de l\'amélioration du profil');
      setIsImproving(false);
    }
  };

  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isChanging, setIsChanging] = useState(false);
  const phrases = [
    'dans vos candidatures.',
    'dans votre carrière.',
    'dans vos entretiens.',
    'dans votre recherche.',
    'dans votre progression.'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsChanging(true);
      setTimeout(() => {
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setIsChanging(false);
      }, 300);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  if (checkingSession) {
    return <div></div>;
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className={`min-h-screen bg-gray-50 ${isLoading ? 'opacity-0' : 'opacity-100 transition-opacity duration-500'}`}>
        <NavBar 
          user={user} 
          onLogout={handleLogout} 
          onProfileImageLoad={handleProfileImageLoad}
        />

        <div className="bg-blue-500 h-40">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-12">
            <div className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-500 rounded-2xl p-8">
              <h2 className="text-3xl font-bold mb-4">
                <span className="text-white">Il est temps d'exceller </span>
                <span 
                  className={`text-blue-200 inline-block ${
                    isChanging ? 'animate-fade-out-up' : 'animate-fade-in-down'
                  }`}
                >
                  {phrases[currentPhraseIndex]}
                </span>
              </h2>
              <form onSubmit={handleSubmitJobUrl} className="relative">
                <div className={`relative mt-2 flex items-center ${urlError ? 'animate-shake' : ''}`}>
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                    <MagnifyingGlassSolidIcon className={`h-6 w-6 ${urlError ? 'text-red-600' : 'text-gray-400'}`} />
                  </div>
                  <input
                    type="url"
                    value={jobUrl}
                    onChange={(e) => {
                      setJobUrl(e.target.value);
                      setUrlError(false);
                    }}
                    placeholder="Collez l'URL de l'offre d'emploi"
                    className={`block w-full pl-16 pr-40 py-6 border-0 rounded-xl focus:ring-2 focus:ring-white focus:ring-opacity-50 focus:outline-none placeholder-gray-500 shadow-sm transition duration-200 ease-in-out
                    ${urlError 
                      ? 'bg-red-50 text-red-600' 
                      : 'bg-white bg-opacity-100 text-gray-900'
                    }`}
                    required
                  />
                  <button
                    type="submit"
                    disabled={loading}
                    className={`absolute right-3 top-1/2 -translate-y-1/2 px-8 py-3.5 text-white rounded-lg font-medium 
                    transition-all duration-300 ease-in-out 
                    before:absolute before:inset-0 before:bg-white/20 before:rounded-lg
                    before:animate-shimmer before:bg-gradient-to-r before:from-transparent before:via-white/20 before:to-transparent
                    active:scale-95
                    backdrop-blur-sm
                    overflow-hidden
                    group
                    flex items-center
                    ${urlError 
                      ? 'bg-red-600 hover:bg-red-700 shadow-lg shadow-red-600/30' 
                      : 'bg-blue-600 hover:bg-blue-500 shadow-lg shadow-blue-500/30'
                    }`}
                  >
                    {loading ? (
                      <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Analyse...
                      </span>
                    ) : (
                      <>
                        <SparklesSolidIcon className="h-5 w-5 mr-2 text-white transition-transform group-hover:scale-110" />
                        <span className="relative z-10">Analyser</span>
                      </>
                    )}
                  </button>
                 
                </div>
              </form>
              <p className="pt-2 text-sm"><strong className="text-blue-600">Sites supportés : </strong><span className="text-blue-900">Indeed - Welcome To The Jungle - TeamTailor - Monster</span></p>
            </div>
          </div>
        </div>

        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 bg-gray-50">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <AnimatePresence mode="wait">
              {!isProfileComplete ? (
                <motion.div
                  key="completion-card"
                  className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden"
                  initial={isFirstVisit ? { opacity: 1 } : false}
                  exit={{ 
                    opacity: 0,
                    scale: 0.95,
                    transition: { duration: 0.5, ease: "easeInOut" }
                  }}
                >
                  <div className="p-6">
                    <motion.div
                      animate={isProfileComplete ? { color: "white" } : { color: "#111827" }}
                      transition={{ duration: 0.5 }}
                      className="flex items-center justify-between mb-4"
                    >
                      <h2 className="text-lg font-semibold">Complétez votre profil</h2>
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        4 étapes
                      </span>
                    </motion.div>
                    
                    <div className="mb-6">
                      <div className="flex justify-between text-sm text-gray-600 mb-1">
                        <span>Progression</span>
                        <span>{completionPercentage}%</span>
                      </div>
                      <div className="w-full bg-blue-100 rounded-full h-2.5">
                        <motion.div 
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{ width: `${completionPercentage}%` }}
                          initial={isFirstVisit ? { width: 0 } : false}
                          animate={{ width: `${completionPercentage}%` }}
                          transition={{ duration: 1, ease: "easeOut" }}
                        ></motion.div>
                      </div>
                    </div>

                    <div className="space-y-4">
                      <div className="flex items-center justify-between p-4 bg-gray-50 rounded-xl">
                        <div className="flex items-center">
                          <BriefcaseIcon className="h-5 w-5 text-gray-400 mr-3" />
                          <span className="text-sm text-gray-600">Expériences</span>
                        </div>
                        {user && user.experiencesCompleted ? (
                          <CheckCircleIcon className="h-5 w-5 text-green-500" />
                        ) : (
                          <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                        )}
                      </div>

                      <div className="flex items-center justify-between p-4 bg-gray-50 rounded-xl">
                        <div className="flex items-center">
                          <AcademicCapIcon className="h-5 w-5 text-gray-400 mr-3" />
                          <span className="text-sm text-gray-600">Formations</span>
                        </div>
                        {user && user.educationCompleted ? (
                          <CheckCircleIcon className="h-5 w-5 text-green-500" />
                        ) : (
                          <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                        )}
                      </div>

                      <div className="flex items-center justify-between p-4 bg-gray-50 rounded-xl">
                        <div className="flex items-center">
                          <HeartIcon className="h-5 w-5 text-gray-400 mr-3" />
                          <span className="text-sm text-gray-600">Centres d'intérêts</span>
                        </div>
                        {user && user.interestsCompleted ? (
                          <CheckCircleIcon className="h-5 w-5 text-green-500" />
                        ) : (
                          <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                        )}
                      </div>

                      <div className="flex items-center justify-between p-4 bg-gray-50 rounded-xl">
                        <div className="flex items-center">
                          <LightBulbIcon className="h-5 w-5 text-gray-400 mr-3" />
                          <span className="text-sm text-gray-600">Compétences</span>
                        </div>
                        {user && user.skillsCompleted ? (
                          <CheckCircleIcon className="h-5 w-5 text-green-500" />
                        ) : (
                          <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                        )}
                      </div>
                    </div>
                  </div>
                </motion.div>
              ) : hasSuggestedJobs ? (
                <motion.div
                  key="suggested-jobs-card"
                  className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden relative h-full"
                  initial={isFirstVisit ? { 
                    opacity: 0,
                    scale: 0.95,
                    y: 20
                  } : false}
                  animate={{ 
                    opacity: 1,
                    scale: 1,
                    y: 0
                  }}
                  transition={{
                    duration: 0.5,
                    delay: isFirstVisit ? 0.3 : 0,
                    ease: "easeOut"
                  }}
                >
                  <div className="p-6 h-full flex flex-col items-center justify-center text-center">
                    <h2 className="text-lg font-semibold text-gray-900 mb-5">Suggestion de métiers</h2>
                    <div className="w-full space-y-4 overflow-y-auto max-h-[400px] scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent">
                      {[...suggestedJobs]
                        .sort((a, b) => parseFloat(b.relevance_score) - parseFloat(a.relevance_score))
                        .map((job, index) => (
                        <div
                          key={index}
                          className="bg-gray-50 rounded-lg p-4 text-left hover:bg-gray-100 transition duration-200"
                        >
                          <div className="flex items-start space-x-4">
                            <CircularProgress value={job.relevance_score} />
                            <div className="flex-1">
                              <h3 className="font-medium text-gray-900">{job.job_title}</h3>
                              <p className="text-gray-500 text-sm">{job.category}</p>
                            </div>
                          </div>
                          {job.required_skills && job.required_skills.length > 0 && (
                            <div className="mt-3 ml-[4.5rem]">
                              <div className="flex flex-wrap gap-1">
                                {job.required_skills.slice(0, 3).map((skill, idx) => (
                                  <span
                                    key={idx}
                                    className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-700"
                                  >
                                    {skill}
                                  </span>
                                ))}
                                {job.required_skills.length > 3 && (
                                  <span className="text-xs text-gray-500">+{job.required_skills.length - 3}</span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  key="ai-improvement-card"
                  className={`rounded-2xl shadow-sm border border-gray-100 overflow-hidden relative h-full
                    ${suggestedJobs && suggestedJobs.length > 0 ? 'bg-white' : 'bg-gradient-to-br from-blue-500 to-blue-600'}`}
                  initial={isFirstVisit ? { 
                    opacity: 0,
                    scale: 0.95,
                    y: 20
                  } : false}
                  animate={{ 
                    opacity: 1,
                    scale: 1,
                    y: 0
                  }}
                  transition={{
                    duration: 0.5,
                    delay: isFirstVisit ? 0.3 : 0,
                    ease: "easeOut"
                  }}
                >
                  <div className="p-6 h-full flex flex-col items-center justify-center text-center">
                    {suggestedJobs && suggestedJobs.length > 0 ? (
                      <>
                        <h2 className="text-lg font-semibold text-gray-900 mb-5">Suggestion de métiers</h2>
                        <div className="w-full space-y-4 overflow-y-auto max-h-[400px] scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent">
                          {[...suggestedJobs]
                            .sort((a, b) => parseFloat(b.relevance_score) - parseFloat(a.relevance_score))
                            .map((job, index) => (
                            <div
                              key={index}
                              className="bg-gray-50 rounded-lg p-4 text-left hover:bg-gray-100 transition duration-200"
                            >
                              <div className="flex items-start space-x-4">
                                <CircularProgress value={job.relevance_score} />
                                <div className="flex-1">
                                  <h3 className="font-medium text-gray-900">{job.job_title}</h3>
                                  <p className="text-gray-500 text-sm">{job.category}</p>
                                </div>
                              </div>
                              {job.required_skills && job.required_skills.length > 0 && (
                                <div className="mt-3 ml-[4.5rem]">
                                  <div className="flex flex-wrap gap-1">
                                    {job.required_skills.slice(0, 3).map((skill, idx) => (
                                      <span
                                        key={idx}
                                        className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-700"
                                      >
                                        {skill}
                                      </span>
                                    ))}
                                    {job.required_skills.length > 3 && (
                                      <span className="text-xs text-gray-500">+{job.required_skills.length - 3}</span>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </>
                    ) : isImproving ? (
                      <>
                        <div className="mb-6">
                          <SparklesSolidIcon className="h-12 w-12 mr-2 text-white transition-transform group-hover:scale-110" />
                        </div>
                        <h2 className="text-xl font-semibold text-white mb-3">Amélioration en cours</h2>
                        <div className="w-full">
                          <div className="w-full bg-white/20 rounded-full h-2.5 mb-2">
                            <motion.div 
                              className="bg-white h-2.5 rounded-full"
                              style={{ width: `${improvementProgress}%` }}
                              initial={{ width: 0 }}
                              animate={{ width: `${improvementProgress}%` }}
                              transition={{ duration: 1, ease: "easeOut" }}
                            ></motion.div>
                          </div>
                          <p className="text-sm text-blue-100">{improvementTips[currentTip]}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mb-6">
                          <SparklesSolidIcon className="h-12 w-12 mr-2 text-white transition-transform group-hover:scale-110" />
                        </div>
                        <h2 className="text-xl font-semibold text-white mb-3">Passons au sérieux</h2>
                        <p className="text-white mb-6 max-w-sm">
                          Votre profil est complet ! Laissez notre IA améliorer vos expériences et formations pour maximiser vos chances.
                        </p>
                        <button
                          onClick={() => {
                            setIsImproving(true);
                            handleImprovement();
                          }}
                          className="bg-white text-blue-600 px-6 py-3 rounded-xl font-medium hover:bg-blue-50 
                          transition-all duration-300 flex items-center space-x-2 shadow-lg"
                        >
                          <SparklesSolidIcon className="h-5 w-5" />
                          <span>Commencer l'amélioration</span>
                        </button>
                      </>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <div className="md:col-span-2 bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
              <div className="p-6">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-lg font-semibold text-gray-900">Mes candidatures générées</h2>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    {lmHistory.length} documents
                  </span>
                </div>
                <div className="space-y-4">
                  {loadingHistory ? (
                    <div className="flex justify-center py-8">
                      <div className="animate-spin rounded-full h-8 w-8 border-2 border-blue-500 border-t-transparent"></div>
                    </div>
                  ) : lmHistory.length > 0 ? (
                    lmHistory.map((item) => (
                      <div key={item.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-xl">
                        <div className="flex items-center space-x-4">
                          <div className={`w-10 h-10 ${getColorFromString(item.jobTitle)} rounded-xl flex items-center justify-center text-white font-semibold`}>
                            {item.jobTitle.charAt(0).toUpperCase()}
                          </div>
                          <div>
                            <h3 className="font-medium">{item.jobTitle}</h3>
                            <div className="flex items-center mt-1">
                              <ClockIcon className="h-4 w-4 text-gray-400 mr-1" />
                              <p className="text-sm text-gray-500">
                                {formatDate(item.createdAt)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <a
                          href={item.pdfUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700"
                        >
                          Télécharger
                          <ArrowLongRightIcon className="ml-2 h-4 w-4" />
                        </a>
                      </div>
                    ))
                  ) : (
                    <div className="h-[300px] flex flex-col items-center justify-center py-8">
                      <DocumentTextIcon className="h-12 w-12 text-gray-400" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">Aucun document</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Commencez par analyser une offre d'emploi pour créer votre premier CV
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {showAnalysisModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <div className="flex justify-between items-center pb-3">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Analyse de l'offre d'emploi
                </h3>
                <button
                  onClick={() => setShowAnalysisModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Fermer</span>
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>

              {isAnalyzing ? (
                <div className="mt-2 flex flex-col items-center justify-center">
                  <div className="animate-spin rounded-full h-10 w-10 border-4 border-blue-500 border-t-transparent"></div>
                  <p className="mt-4 text-sm text-gray-500">{analysisStatus}</p>
                </div>
              ) : analysisResult ? (
                <div className="mt-4">
                  <div className="mb-6">
                    <h4 className="text-md font-semibold mb-3">Résultat de l'analyse</h4>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center">
                          <CircularProgress value={analysisResult.score} />
                          <span className="ml-3 text-lg font-semibold">
                            {analysisResult.score}%
                          </span>
                        </div>
                      </div>
                      <p className="text-gray-700 mb-4">{analysisResult.commentaire}</p>
                      
                      {analysisResult.softs && analysisResult.softs.length > 0 && (
                        <div className="mb-4">
                          <h5 className="font-medium mb-2">Points forts</h5>
                          <div className="space-y-2">
                            {analysisResult.softs.map((soft, index) => (
                              <div key={index} className="flex items-center">
                                <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                                <span>{soft.soft_skill}: {soft.relevance_to_offer}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {analysisResult.faiblesses && analysisResult.faiblesses.length > 0 && (
                        <div className="mb-4">
                          <h5 className="font-medium mb-2">Points à améliorer</h5>
                          <div className="space-y-2">
                            {analysisResult.faiblesses.map((weakness, index) => (
                              <div key={index} className="flex items-center">
                                <XMarkIcon className="h-5 w-5 text-red-500 mr-2" />
                                <span>{weakness}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {analysisResult.hards && analysisResult.hards.length > 0 && (
                        <div>
                          <h5 className="font-medium mb-2">Compétences techniques</h5>
                          <div className="flex flex-wrap gap-2">
                            {analysisResult.hards.map((hard, index) => (
                              <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm">
                                {hard}
                              </span>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {secondAssistantResult && (
                    <div className="mt-6">
                      <h4 className="text-md font-semibold mb-3">Lettre de motivation générée</h4>
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <div
                          className="text-gray-700 leading-relaxed"
                          dangerouslySetInnerHTML={{ __html: secondAssistantResult }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="mt-2">
                  <p className="text-sm text-red-500">{analysisStatus}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
